import React from 'react';

import './presentation.scss';
import presentationWEBP from '../assets/images/presentation.webp';
import presentationPNG from '../assets/images/presentation.png';
import presentationAVIF from '../assets/images/presentation.avif';

export default function Presentation() {
  return (
    <section id="presentation" className="d-flex align-items-center">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-6 d-lg-flex flex-lg-column justify-content-center align-items-stretch pt-5 pt-lg-0 order-2 order-lg-1"
            data-aos="fade-up"
          >
            <div>
              <h1>L&apos;application EKRILY</h1>
              <h2>
                Vous préférez louer au lieu de vendre vos trucs personnels ou bien vous avez besoin de trucs que vous ne voulez pas acheter,
                Ekrily est la solution. N&apos;hésitez pas à télécharger notre application mobile gratuite sous Android/IOS
              </h2>
              <a href="https://play.google.com/store/apps/details?id=com.ekrily" className="download-btn">
                <i className="bx bxl-play-store" /> Google Play
              </a>
              <a href="https://appstoreconnect.apple.com/apps/1580288062/appstore/info" className="download-btn">
                <i className="bx bxl-apple" /> App Store
              </a>
            </div>
          </div>
          <div className="col-lg-6 d-lg-flex flex-lg-column align-items-stretch order-1 order-lg-2 presentation-img" data-aos="fade-up">
            <picture>
              <source
                className="img-fluid"
                type="image/webp"
                srcSet={`${presentationWEBP} 300w, ${presentationWEBP} 768w, ${presentationWEBP} 1280w`}
                sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
              />
              <source
                className="img-fluid"
                type="image/avif"
                srcSet={`${presentationAVIF} 300w, ${presentationAVIF} 768w, ${presentationAVIF} 1280w`}
                sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
              />
              {/* <source className="img-fluid" type="image/webp" srcSet={presentationJP2} /> */}
              <img className="img-fluid" src={presentationPNG} alt="Location de Ekrily" width="100%" height="auto" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
}
