import React, { Suspense } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './assets/scss/main.scss';
import 'bootstrap/dist/css/bootstrap.css';
import { createTheme, ThemeProvider } from '@mui/material';
import Header from './Header';
import Presentation from './Presentation/Presentation';
const ScrollToTop = React.lazy(() => import('./components/ScrollToTop'));
const Footer = React.lazy(() => import('./Footer'));
const Gallery = React.lazy(() => import('./Gallery'));
const Contact = React.lazy(() => import('./Contact'));
const ResetPassword = React.lazy(() => import('./ResetPassword'));
const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy'));
const TermsConditions = React.lazy(() => import('./TermsConditions'));
const ConfirmRegistration = React.lazy(() => import('./ConfirmRegistration'));
const Feature = React.lazy(() => import('./Feature'));

const theme = createTheme({
  palette: {
    primary: {
      main: '#009FE3',
    },
    secondary: {
      main: '#590212',
    },
  },
  typography: {
    fontFamily: [
      'RNSMiles',
      '-apple-system',
      'BlinkMacSystemFont',
      'Open Sans,sans-serif',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

export default function App() {
  // const [open, setOpen] = useState(isMobile);
  // const onCloseModal = () => setOpen(false);
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  return (
    <div className="App">
      {/* <Modal className="modal-container custom-map-modal"
        show={open}
        onHide={onCloseModal}
        animation={true}
        dialogClassName="custom-map-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Télécharger l&apos;app Mobile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DownloadButton/>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="button-theme button-theme-blue" onClick={onCloseModal}>Continuer vers le site</Button>
        </Modal.Footer>
      </Modal> */}
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Suspense fallback={<div>Chargement...</div>}>
            <ScrollToTop />
          </Suspense>
          <Route exact={true} path={'/'}>
            <Header />
            <Presentation />
            <Suspense fallback={<div>Chargement...</div>}>
              <main id="main">
                <Feature />
                <Gallery />
                <Contact />
              </main>
              <Footer />
            </Suspense>
          </Route>
          <Route path={'/confirmation-email/:userId'}>
            <Header />
            <Suspense fallback={<div>Chargement...</div>}>
              <main id="main">
                <ConfirmRegistration />
              </main>
              <Footer />
            </Suspense>
          </Route>
          <Route path={'/reset-password/:userId'}>
            <Header />
            <Suspense fallback={<div>Chargement...</div>}>
              <main id="main">
                <ResetPassword />
              </main>
              <Footer />
            </Suspense>
          </Route>
          <Route path={'/privacy-policy'}>
            <Header />
            <Suspense fallback={<div>Chargement...</div>}>
              <main id="main">
                <PrivacyPolicy />
              </main>
              <Footer />
            </Suspense>
          </Route>
          <Route path={'/terms-conditions'}>
            <Header />
            <Suspense fallback={<div>Chargement...</div>}>
              <main id="main">
                <TermsConditions />
              </main>
              <Footer />
            </Suspense>
          </Route>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}
