import React from 'react';
import './header.scss';
import logo from '../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';
// import { useEffect } from 'react';

export default function Header() {
  const [scrollClass, setScrollClass] = useState('header-transparent');

  window.addEventListener('scroll', () => {
    let scrollClass = 'header-scroll';
    if (window.scrollY === 0) {
      scrollClass = 'header-transparent';
    }
    setScrollClass(scrollClass);
  });
  return (
    <header id="header" className={`fixed-top ${scrollClass}`}>
      <div className="container d-flex align-items-center">
        <div className="logo mr-auto">
          <h1 className="text-light">
            <Link to="/">
              <img src={logo} alt="Solution mobile pour toutes les locations entre particuliers en Franche/Tunisie" />
            </Link>
          </h1>
        </div>

        <nav className="nav-menu d-none d-lg-block">
          <ul>
            <li>
              <a href="/#features">Fonctionnalités</a>
            </li>
            <li>
              <a href="/#gallery">Gallerie</a>
            </li>
            {/* <li><a href="#pricing">Prix</a></li> */}
            {/* <li>
              <a href="#faq">F.A.Q</a>
            </li> */}
            <li>
              <a href="/#contact">Contactez-nous</a>
            </li>
            {/* <li>
              <a href="#firststeps">Premiers pas</a>
            </li> */}
          </ul>
        </nav>
      </div>
    </header>
  );
}
